
<template>
    <b-overlay
		variant="white"
		spinner-variant="primary"
		blur="0"
		opacity=".75"
		rounded="sm"
		:show="templates === null"
	>
        <b-card title="E-Mail Templates">
            <div class="mb-2">
                <span class="d-inline-block" tabindex="0">
                    <b-button
                        variant="primary"
                        @click="() => $router.push('/email-templates/new')"
                    >Create New Template</b-button>
                </span>
            </div>
            <b-table v-if="templates && templates.length > 0" :items="templates" :fields="fields">
                <template #cell(id)="data">
                    {{ data.item.id }}
                    <feather-icon icon="CopyIcon" class="copy-id" @click="copyId(data.item)" title="Copy ID" />
                </template>
                <template #cell(title)="data">
                    {{ data.item.title }}
                </template>
                <template #cell(variables)="data">
                    <div v-if="getVariables(data.item).length > 0">
                        <b-badge v-for="(variable, index) in getVariables(data.item)" :key="index" variant="light" class="mr-1 mt-1">{{ variable }}</b-badge>
                    </div>
                    <div v-else>
                        <em>None</em>
                    </div>
                </template>
                <template #cell(actions)="data">
                    <b-button variant="primary" @click="edit(data.item.id)" class="mr-1">Edit</b-button>
                    <b-button variant="danger" @click="deleteConfirm(data.item.id)">Delete</b-button>
                </template>
            </b-table>
        </b-card>
    </b-overlay>
</template>

<script>
import { BBadge, BCard, BButton, BTable, BOverlay } from "bootstrap-vue";
import { mapGetters } from "vuex";
import { collection, getDocs, deleteDoc, doc } from '@firebase/firestore';
import FeatherIcon from '../@core/components/feather-icon/FeatherIcon.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { getTemplateVariables } from "../libs/template-vars";

export default {
    components: {
        BCard,
        BButton,
        BTable,
        BOverlay,
        BBadge,
        FeatherIcon
    },
    data: () => ({
        templates: null,
        fields: [
            { key: 'id', label: 'Template ID' },
            { key: 'title', label: 'Title' },
            { key: 'variables', label: 'Variables' },
            { key: 'actions', label: 'Actions' },
        ]
    }),
	computed: {
		...mapGetters({
			user: "auth/user"
		})
	},
    watch: {
        "user.data.uid"() {
            this.fetchTemplates();
        }
    },
    mounted() {
        if (this.user.data) this.fetchTemplates();
    },
    methods: {
        getVariables(tpl) {
            return getTemplateVariables(tpl);
        },
        copyId(item) {
            navigator.clipboard.writeText(item.id);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'ID copied to your clipboard!',
                    icon: 'CopyIcon',
                    variant: 'success',
                },
            });
        },
        edit(id) {
            this.$router.push(`/email-templates/${id}`)
        },
        async fetchTemplates() {
            const templateDocs = await getDocs(collection(this.$firestore, "users", this.user.data.uid, "email_templates"));
            const templates = [];
            templateDocs.forEach((doc) => {
                templates.push({
                    id: doc.id,
                    ...doc.data()
                })
            });
            this.templates = templates;
        },
		deleteConfirm(id) {
			this.$swal({
				title: 'Are you sure?',
				text: 'Requests using this template will not work anymore. This also affects users who use a version of your application that works with this template! This action cannot be undone.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (!result.value) return;

				deleteDoc(doc(this.$firestore, "users", this.user.data.uid, "email_templates", id)).then(() => {
					this.fetchTemplates();
					this.$swal({
						icon: 'success',
						title: 'Deleted!',
						text: 'The template has been deleted.',
						customClass: {
							confirmButton: 'btn btn-success',
						},
					});
				}).catch(e => {
                    console.error(e);

                    this.$swal({
                        icon: 'danger',
                        title: 'Oops, something went wrong!',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                })
			})
		},
    }
}
</script>

<style lang="scss" scoped>
.dark-layout .badge.badge-light {
    color: #555
}

.copy-id {
    cursor: pointer;
    &:hover {
        color: #888;
    }
}

.badge-light {
    color: #555;
}
</style>